.login-page {
    max-width: 360px;
    padding: 20px;
    margin: 0 auto;
    margin-top: 120px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);

    .title {
        text-align: center;
        font-weight: 500;
        font-size: 26px;
        margin-top: 0;
    }

    .copyright {
        font-size: 12px;
        text-align: center;
    }
}