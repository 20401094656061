.attribute-value-modal {
    [class~="ant-modal-body"] {
        overflow: hidden !important;
    }
    [class~="ant-modal-footer"] {
        // margin-top: 0;
        padding-top: 20px;
        border-top: 1px solid rgba(5, 5, 5, 0.06);
    }
    .search {
        margin-bottom: 16px;
    }
    .list-wrapper {
        padding: 16px 0;
        .list {
            .list-item {
                justify-content: start;
                padding: 6px 12px;
                .content {
                    margin-left: 1rem;
                    gap: 0 !important;
                }
            }
        }
    }
}