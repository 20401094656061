.save-page {
    .action-bar {
        margin-top: 16px;
        background: #fff;
        padding: 16px 24px;
        text-align: right;
        button {
            min-width: 100px;
        }
        button + button,
        a + button {
            margin-left: 10px;
        }
    }
}