.c-fieldset {
    padding: 1em;
    border: 1px solid #f0f0f0;
    legend {
        font-size: 16px;
        display: unset;
        width: unset;
        padding: 0 10px;
        border-bottom: unset;
        margin: unset;
    }
    &:not(:last-child) {
        margin-bottom: 1rem;
    }
}