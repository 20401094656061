@import "@/assets/scss/variables.scss";

.app-header {
    height: $heighAppHeader !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 !important;
    background: #fff !important;
    box-shadow: 4px 4px 40px 0 rgba(0,0,0,.05) !important;

    .icon-collapsed {
        padding: 0 22px;
        height: 64px;
        cursor: pointer;
        transition: all .3s,padding 0s;
        font-size: 20px;
        &:hover {
            background-color: rgba(0, 0, 0, .025);
        }
        .trigger {
            font-size: 22px;
        }
    }

    .menu-top-right {
        line-height: 56px !important;
        display: flex;
        justify-content: flex-end;
        border: none;
        .avatar {
            display: inline-flex;
            justify-content: center;
        }
        [class~="ant-menu-submenu"] {
            padding-right: 5px !important;
            padding-left: 5px !important;
            &::after {
                right: 5px;
                left: 5px;
            }
        }
        [class~="ant-menu-submenu-title"] {
            min-width: 160px;
            width: 100%;
        }
        [class~="ant-menu-submenu-active"] {
            background: $navBgActiveColor;
        }
    }
}