.login-form {
    [class~="ant-form-item-row"] {
        margin-bottom: 28px;
    }
    [class~="ant-input-affix-wrapper"] {
        padding: 6px 12px;
        height: 36px;
        line-height: 39px;
        border-radius: 4px;
    }
    .btn-login {
        width: 100%;
        height: 36px;
    }
}