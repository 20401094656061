.public-layout {
    .container {
        max-width: 1000px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 15px;
        padding-right: 15px;
        height: 100%;
    }

    .app-header {
        background: #fff;
        position: fixed;
        width: 100%;
        box-shadow: 0 2px 8px #f0f1f2;
        z-index: 10;
        padding: 0;
        .app-logo {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            a {
                text-decoration: none;
                line-height: 64px;
                font-size: 21px;
                display: inline-block;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    .app-content {
        margin-top: 64px;
        background: #fff;
    }
}